













































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Chance from 'chance'
import {
  User,
  ChatConversation,
  ChatMessage,
  Environment,
  EnvironmentUser,
  FileManagerFile
} from '@/models'
import ChatService from '@/services/ChatService'
import UploadModal from '@/components/fields/file/UploadModal.vue'
import { ChatState } from '@/models/Chat'
import getEnv from '@/plugins/getEnv'
import moment from '@/plugins/moment'
import Axios from 'axios'

const chance = new Chance()

@Component({
  components: {
    UploadModal
  }
})
export default class EnvironmentMessages extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser

  chatService: ChatService | null = null

  connection: WebSocket | null = null

  itemsMenu = [{ title: 'Terminar sesión' }]

  chatState: ChatState = 'initial'
  ratingView = false

  messageList: ChatMessage[] = []
  newMessage: string = ''
  defaultConversation: ChatConversation = {
    _id: '',
    userId: '',
    username: '',
    subject: '',
    priority: 0,
    lastMessage: '',
    userAvatar: '',
    environmentId: '',
    channelName: '',
    category: ''
  }
  conversation: ChatConversation = this.defaultConversation

  subject: string = ''
  shortDescription: string = ''
  rating: number = 0
  comment: string = ''
  uploadModalOpen = false
  buttonLoad = false
  channelName = ''
  latestMessage: ChatMessage = {
    userId: '',
    nonce: '',
    contents: '',
    embed: {
      image: '',
      link: ''
    },
    loaded: false
  }
  messageError = false

  conversations: ChatConversation[] = []
  isOldChat: boolean = false
  chanelRules = [(v: any) => !!v || 'Debe seleccionar un canal']
  //newMessageCount:{[index:string]:number} = {}
  /*
  @Watch('messageList')
  messageAdded() {
    console.log('WATCHER', this.messageList)
  }
  */

  /*
  @Watch('latestMessage')
  messageAdded() {
    console.log('WATCHER', this.latestMessage)
  }
  */
  get types() {
    if (
      this.environment.tellusChannels &&
      this.environment.tellusChannels.length > 0
    ) {
      return this.environment.tellusChannels.map((c) => ({
        label: c.category,
        value: c.channelName
      }))
    }
    return [{ label: 'Soporte', value: this.environment._id }]
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }
  // Open navigation-drawer chat
  set openChat(v: boolean) {
    this.$emit('input', v)
  }

  get openChat() {
    return this.value
  }

  get lastReadMessageId() {
    return localStorage.tellusLastReadMessageId
  }

  set lastReadMessageId(v: string) {
    localStorage.tellusLastReadMessageId = v
  }

  get unreadMessageCount() {
    const lastReadIndex = this.messageList.findIndex(
      (m) => m._id === this.lastReadMessageId
    )
    if (!this.messageList || !this.messageList.length) {
      return 0
    }
    if (this.messageList[this.messageList.length - 1].userType === 'codeless') {
      return 0
    }
    if (!this.lastReadMessageId) {
      return (
        this.messageList.length -
        this.messageList.map((m) => m.userType).lastIndexOf('codeless')
      )
    }
    return (
      this.messageList.length -
      Math.max(
        this.messageList.map((m) => m._id).lastIndexOf(this.lastReadMessageId),
        this.messageList.map((m) => m.userType).lastIndexOf('codeless')
      ) -
      1
    )
  }

  get headersOfConversations() {
    const headers = [
      { text: 'Canal', value: 'channelName' },
      { text: 'Descripción', value: 'subject' },
      { text: 'Fecha Inicio', value: 'createdAt' },
      { text: '', value: 'actions', sortable: false }
    ]
    return headers
  }
  get getConversationsOpens() {
    if (!this.conversations[0]) return []
    let conversations = this.conversations
      .filter((e) => e.status === 'open' && e.userId === this.user._id)
      .map((e) => {
        e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm')
        return e
      })
    conversations = conversations.sort(
      (a, b) =>
        moment(b.lastMessageTimestamp).valueOf() -
        moment(a.lastMessageTimestamp).valueOf()
    )
    return conversations
  }
  get newMessageCount(): { [index: string]: number } {
    if (!this.conversations[0]) return {}
    const conversations = this.conversations
      .filter((e) => e.status === 'open' && e.userId === this.user._id)
      .map((e) => {
        e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm')
        return e
      })
    const newMessageCount: { [index: string]: number } = {}
    for (const conversation of conversations) {
      if (!conversation._id) continue
      newMessageCount[conversation._id] = conversation.userUnreadCount || 0
    }
    return newMessageCount
  }
  @Watch('value')
  valueChanged(v: boolean) {
    if (
      v &&
      this.messageList &&
      this.messageList[this.messageList.length - 1]
    ) {
      this.lastReadMessageId =
        this.messageList[this.messageList.length - 1]._id || ''
    }
    this.$emit('unreadCount', this.unreadMessageCount)
  }

  async mounted() {
    setTimeout(async () => {
      const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
      this.chatService = new ChatService(
        this.environment._id,
        btoa(
          JSON.stringify({
            d: apiUrl.host,
            s: this.$store.getters['auth/session'],
            t: new Date()
          })
        )
      )
      let conversations = await this.chatService.getConversations()
      if (
        conversations &&
        conversations.some(
          (e) => e.status === 'open' && e.userId === this.user._id
        )
      ) {
        this.chatState = 'list'
        this.conversations = conversations
        this.openConversationsSocket()
        setTimeout(() => {
          this.sendUnreadCount()
        }, 100)
      } else {
        this.chatState = 'initial'
      }
      return //this.syncConversation()
    }, 1000)
  }
  async getConversations() {
    const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
    let sessionId = btoa(
      JSON.stringify({
        d: apiUrl.host,
        s: this.$store.getters['auth/session'],
        t: Date.now()
      })
    )
    const apiBack = Axios.create({
      baseURL: 'https://tellus-v2-beta.services.simplex.biz/api',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Codeless ${this.environment._id} ${sessionId}`,
        TellusDate: Date.now()
      }
    })
    try {
      const request = await apiBack.get('/conversations')
      return request.data.items
    } catch (e) {
      throw e
    }
  }
  async handleBackToList() {
    if (!this.chatService) return
    this.chatState = 'list'
    this.newMessage = ''
    this.subject = ''
    this.shortDescription = ''
    this.channelName = ''
    this.conversation = this.defaultConversation
    this.messageList = []
    this.isOldChat = false
    window.localStorage.tellusActiveConversationId = ''
    window.localStorage.tellusLastReadMessageId = ''
    const conversations = await this.chatService.getConversations()
    if (conversations && conversations.some((e) => e.status === 'open')) {
      this.chatState = 'list'
      this.conversations = conversations
    } else {
      this.chatState = 'initial'
    }
  }
  beforeDestroy() {
    this.connection?.close()
  }

  @Watch('$store.state.sync.tellusActiveConversationId')
  async syncConversation() {
    if (!this.chatService) return
    this.conversation =
      (await this.chatService.lastConversation()) || this.defaultConversation
    await this.fetchActiveConversation()
  }

  async handleConversation(conversationId: string) {
    if (!this.chatService) return
    this.conversation =
      this.getConversationsOpens.find((e) => e._id === conversationId) ||
      this.defaultConversation
    //(await this.chatService.getConversationById(conversationId)) || this.defaultConversation
    this.isOldChat = true
    this.conversation.userUnreadCount = 0
    //this.newMessageCount[conversationId] = 0
    this.sendUnreadCount()
    await this.fetchActiveConversation()
  }

  async fetchActiveConversation() {
    if (!this.conversation || !this.chatService) return
    if (!this.conversation._id) {
      this.conversation = this.defaultConversation
      this.cleanData()
      return
    }

    this.chatState = 'chat'
    this.ratingView = false
    // Obtengo la lista de mensajes de esa última conversación
    const messages = await this.chatService.getMessages(this.conversation._id)
    this.messageList = messages
    //messages.forEach((item) => this.messageList.push(item))

    if (this.value && this.messageList && this.messageList.length > 0) {
      this.lastReadMessageId = this.messageList[0]._id || ''
    }
    this.$emit('unreadCount', this.unreadMessageCount)

    this.chatScrollToBottom()
    this.latestMessage.loaded = true

    await this.openConversationSocket()
  }
  sendUnreadCount() {
    let unreadCount = 0
    for (let count in this.newMessageCount) {
      unreadCount += this.newMessageCount[count]
    }
    this.$emit('unreadMessageCountByClient', unreadCount)
  }

  openModal() {
    this.uploadModalOpen = true
  }

  endSession() {
    if (this.conversation && this.conversation._id) {
      this.conversation.status = 'closed'
      this.isOldChat = false
      if (this.conversations[0]) {
        const conversationFinished = this.conversations.find(
          (e) => e._id === this.conversation._id
        ) || { status: 'closed' }
        conversationFinished.status = 'closed'
      }
      this.chatService!.patchConversation(this.conversation._id, {
        status: 'closed'
      }).then((res) => {
        this.buttonLoad = false
        this.ratingView = true
        this.chatScrollToBottom()
      })
    } else {
      this.conversationNotFound()
    }
  }

  handleImageSelect(file: FileManagerFile) {
    this.sendImage(file)
  }
  async openConversationsSocket() {
    // this.buttonLoad = true
    if (this.connection) return
    this.connection = this.chatService!.openSocket()

    this.connection.onclose = () => {
      this.connection = null
      if (['chat', 'end'].includes(this.chatState)) {
        this.openConversationsSocket()
      }
    }

    this.connection.onopen = async (ev) => {
      const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
      this.connection!.send(
        JSON.stringify({
          action: 'auth',
          data: `Codeless ${this.environment._id} ${btoa(
            JSON.stringify({
              d: apiUrl.host,
              s: this.$store.getters['auth/session'],
              t: Date.now()
            })
          )}`
        })
      )
      for (const conversation of this.getConversationsOpens) {
        this.connection!.send(
          JSON.stringify({
            action: 'joinConversation',
            data: {
              conversationId: conversation._id
            }
          })
        )
      }
      this.connection?.addEventListener('message', async (event) => {
        // Escucha los mensajes
        let objResponse = JSON.parse(event.data)
        let message = objResponse.data as ChatMessage
        if (objResponse.action === 'message') {
          if (this.value && message._id) this.lastReadMessageId = message._id
          // Si llega un action 'message' lo escucha y envía a la lista de mensajes.
          this.latestMessage = message
          this.latestMessage.loaded = true
          if (
            this.conversation._id === message.conversationId &&
            (!message.nonce ||
              !this.messageList.some((m) => m.nonce === message.nonce))
          ) {
            this.messageList.push(message)
          }
          if (
            this.conversation._id !== message.conversationId &&
            message.conversationId
          ) {
            const conversation = this.conversations.find(
              (e) => e._id === message.conversationId
            )
            if (conversation) {
              conversation.lastMessage = message.contents
              conversation.lastMessageTimestamp = message.timestamp
              conversation.userUnreadCount =
                this.chatState === 'chat'
                  ? 0
                  : (conversation.userUnreadCount || 0) + 1
              this.sendUnreadCount()
            }
          }

          this.$emit('unreadCount', this.unreadMessageCount)
          //@ts-ignore
          setTimeout(() => {
            this.chatScrollToBottom()
          }, 100)
        } else if (objResponse.action === 'conversationUpdated') {
          if (objResponse.data.status === 'closed') {
            // Cuando recibe un action conversationUpdated el response tiene un status y si es closed nos muestra la vista de ratingView
            this.ratingView = true
            this.buttonLoad = false
          }
        }
      })
    }
  }
  async openConversationSocket() {
    // this.buttonLoad = true
    if (this.connection) {
      this.chatState = 'chat'
      if (this.isOldChat) {
        this.isOldChat = false
        return
      }
      this.newMessage = this.shortDescription
      this.isOldChat = false
      //await this.sendMessage()
      return
    }
    this.connection = this.chatService!.openSocket()

    this.connection.onclose = () => {
      this.connection = null
      if (['chat', 'end'].includes(this.chatState)) {
        this.openConversationSocket()
      }
    }

    this.connection.onopen = async (ev) => {
      const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
      this.connection!.send(
        JSON.stringify({
          action: 'auth',
          data: `Codeless ${this.environment._id} ${btoa(
            JSON.stringify({
              d: apiUrl.host,
              s: this.$store.getters['auth/session'],
              t: Date.now()
            })
          )}`
        })
      )
      this.connection!.send(
        JSON.stringify({
          action: 'joinConversation',
          data: {
            conversationId: this.conversation._id
          }
        })
      )

      // Envía el primer mensaje que es el que describe el problema al
      if (!this.isOldChat) {
        this.newMessage = this.shortDescription
        this.isOldChat = false
        //await this.sendMessage()
      }

      this.connection?.addEventListener('message', async (event) => {
        // Escucha los mensajes
        let objResponse = JSON.parse(event.data)
        let message = objResponse.data as ChatMessage
        if (objResponse.action === 'message') {
          if (this.value && message._id) this.lastReadMessageId = message._id
          // Si llega un action 'message' lo escucha y envía a la lista de mensajes.
          this.latestMessage = message
          this.latestMessage.loaded = true
          if (
            !message.nonce ||
            !this.messageList.some((m) => m.nonce === message.nonce)
          ) {
            this.messageList.push(message)
          }
          if (
            this.conversation._id !== message.conversationId &&
            message.conversationId
          ) {
            const conversation = this.conversations.find(
              (e) => e._id === message.conversationId
            )
            if (conversation) {
              conversation.lastMessage = message.contents
              conversation.lastMessageTimestamp = message.timestamp
              conversation.userUnreadCount =
                this.chatState === 'chat'
                  ? 0
                  : (conversation.userUnreadCount || 0) + 1
              this.sendUnreadCount()
            }
          }
          this.$emit('unreadCount', this.unreadMessageCount)
          //@ts-ignore
          setTimeout(() => {
            this.chatScrollToBottom()
          }, 100)
        } else if (objResponse.action === 'conversationUpdated') {
          if (objResponse.data.status === 'closed') {
            // Cuando recibe un action conversationUpdated el response tiene un status y si es closed nos muestra la vista de ratingView
            this.ratingView = true
            this.buttonLoad = false
          }
        }
      })
      this.chatState = 'chat'
    }
  }

  // Send first conversation and Open Socket
  async sendConversation() {
    const firstConversation: ChatConversation = {
      _id: '',
      userId: this.user._id,
      username: this.user.email,
      subject: this.subject,
      priority: 1,
      lastMessage: this.shortDescription,
      userAvatar: this.userAvatar,
      environmentId: this.environment._id,
      channelName: this.channelName,
      category:
        (this.environment.tellusChannels || []).find(
          (c: any) => c.channelName === this.channelName
        )?.category || ''
    }
    this.buttonLoad = true
    this.conversation = await this.chatService!.postConversation(
      firstConversation
    )

    if (this.conversation && this.conversation._id) {
      this.conversations = (await this.chatService?.getConversations()) || []
      const messages = await this.chatService!.getMessages(
        this.conversation._id
      )
      this.messageList = messages
      //messages.forEach((item) => this.messageList.push(item))
      this.chatScrollToBottom()
      this.buttonLoad = false
      if (this.value && this.messageList && this.messageList.length > 0) {
        this.lastReadMessageId = this.messageList[0]._id || ''
      }
      this.$emit('unreadCount', this.unreadMessageCount)
      window.localStorage.tellusActiveConversationId = this.conversation._id
      this.newMessage = this.shortDescription
      this.isOldChat = false
      this.chatState = 'chat'
      return await this.sendMessage()
      //return this.openConversationSocket()
    } else {
      this.buttonLoad = false
      this.conversationNotFound()
    }
  }

  // Send image
  sendImage(file: FileManagerFile) {
    this.latestMessage = {
      userId: this.user._id,
      userType: 'codeless',
      nonce: chance.guid(),
      contents: '',
      embed: {
        image: `https://${file.bucket}.s3.amazonaws.com/${file.key}` || '',
        link: `https://${file.bucket}.s3.amazonaws.com/${file.key}` || ''
      }
    }

    this.latestMessage.loaded = false

    if (this.conversation && this.conversation._id) {
      this.chatService!.postMessage(this.conversation._id, this.latestMessage)
      this.messageList.push(this.latestMessage)
      this.$nextTick(() => this.chatScrollToBottom())
    } else {
      this.conversationNotFound()
    }
  }

  // Send newMessage
  async sendMessage() {
    if (this.newMessage.length === 0) return

    this.latestMessage = {
      userId: this.user._id,
      userType: 'codeless',
      contents: this.newMessage,
      nonce: chance.guid(),
      embed: {
        image: '',
        link: ''
      }
    }
    this.latestMessage.loaded = false
    this.newMessage = ''
    //fix socket disconection
    if (this.connection && this.connection.readyState !== 1) {
      this.connection?.close && this.connection.close()
    } else if (!this.connection) {
      await this.openConversationsSocket()
    }
    // fix for duplicate messages
    const messageFromUser = this.messageList.filter(
      (m) => m.userType === 'codeless'
    )
    const lastMessageFromUser = messageFromUser[messageFromUser.length - 1]
    if (
      lastMessageFromUser &&
      lastMessageFromUser.contents === this.latestMessage.contents
    ) {
      return
    }

    if (this.conversation && this.conversation._id) {
      this.chatService!.postMessage(this.conversation._id, this.latestMessage)
      this.messageList.push(this.latestMessage)
      this.latestMessage.loaded = true
      this.$nextTick(() => this.chatScrollToBottom())
    } else {
      this.conversationNotFound()
    }
  }

  chatScrollToBottom() {
    const chatContainer = this.$refs.container as Element
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }

  cleanData() {
    this.chatState = 'initial'
    if (this.connection) {
      //;(this.connection as WebSocket).close()
      //this.connection = null
    }
    this.newMessage = ''
    this.conversation = this.defaultConversation
    this.messageList = []
    this.subject = ''
    this.shortDescription = ''
    this.rating = 0
    this.comment = ''
    //this.connection = null
    this.buttonLoad = false
    this.ratingView = false
  }

  async sendRating() {
    let rating = {
      userRating: this.rating,
      userFeedback: this.comment
    }

    this.chatState = 'end'
    window.localStorage.tellusActiveConversationId = ''

    try {
      if (this.conversation && this.conversation._id) {
        if (rating.userRating || rating.userFeedback) {
          await this.chatService!.patchConversation(
            this.conversation._id,
            rating
          )
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  conversationNotFound() {
    this.messageError = true
    this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Conversation Not Found',
      color: 'error'
    })
    console.error('Conversation Not Found')
    throw new Error('Conversation Not Found' as string)
  }
}
