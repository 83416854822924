import axios, { AxiosInstance } from 'axios'
import store from '@/store'
import { ChatConversation, ChatMessage } from '@/models/Chat'
import {
  ChatConversationList,
  ChatConversationResponse,
  ChatConversationsResponse
} from '../models/Chat'
import getEnv from '@/plugins/getEnv'

export default class ChatService {
  chatService: AxiosInstance

  /**
   * Service for real-time chat interactions using TELLUS.
   * Please check your ENV variants if experiencing problems.
   * @param environmentId , String, Environment ID
   * @param sessionId , String, Session ID
   */
  constructor(public environmentId: string, public sessionData: any) {
    this.chatService = axios.create({
      baseURL:
        getEnv('VUE_APP_CHAT_API_URL') ||
        'https://tellus-v2-beta.services.simplex.biz',
      withCredentials: false,
      headers: {
        Accept: 'applicacion/json',
        'Content-Type': 'application/json'
      },
      // Add authorization header to all requests
      transformRequest: [
        (data, headers) => {
          headers['Authorization'] =
            'Codeless ' +
            environmentId +
            ' ' +
            btoa(
              JSON.stringify({
                ...sessionData,
                t: Date.now()
              })
            )
          return JSON.stringify(data)
        }
      ]
    })
  }

  /**
   * Instanciates a new WebSocket
   * @returns WebSocket
   */
  openSocket(): WebSocket {
    return new WebSocket(
      getEnv('VUE_APP_CHAT_SOCKET_ADDRESS') ||
        'wss://tellus-v2-beta.services.simplex.biz'
    )
  }

  /**
   * ASYNC, Get last recorded conversation in session
   * @returns Promise<ChatConversation>
   */
  async lastConversation(): Promise<ChatConversation | undefined> {
    try {
      const { data } = await this.chatService.get<ChatConversationResponse>(
        `/api/conversations/last`,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )
      return data.item
    } catch (err) {
      console.error('[TELLUS] Error fetching last conversation:', err)
    }
  }

  /**
   * ASYNC, Get conversation in session by id
   * @returns Promise<ChatConversation>
   */
  async getConversations(): Promise<ChatConversation[] | undefined> {
    try {
      const { data } = await this.chatService.get<ChatConversationsResponse>(
        `/api/conversations`,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )
      return data.items
    } catch (err) {
      console.error('[TELLUS] Error fetching the conversation:', err)
    }
  }
  /**
   * ASYNC, Get conversation in session by id
   * @returns Promise<ChatConversation>
   */
  async getConversationById(
    conversationId: string
  ): Promise<ChatConversation | undefined> {
    try {
      const { data } = await this.chatService.get<ChatConversationResponse>(
        `/api/conversations/${conversationId}`,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )
      return data.item
    } catch (err) {
      console.error('[TELLUS] Error fetching the conversation:', err)
    }
  }
  /**
   * ASYNC, Get Array of messages for current conversation
   * @param conversationId , string, Current Conversation ID
   * @returns Promise<ChatMessage[]>
   */
  async getMessages(conversationId: string): Promise<ChatMessage[]> {
    try {
      const { data } = await this.chatService.get<ChatConversationList>(
        `/api/conversations/${conversationId}/messages`,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )
      return data.items
    } catch (err) {
      store.dispatch('snackbar/showSnackbar', {
        text: 'Request Error: ' + err,
        color: 'error'
      })
      console.error(err)
      throw new Error(err as string)
    }
  }

  /**
   * ASYNC, Updates a Message
   * @param conversationId , string, Current Conversation ID
   * @param value , ChatMessage, Current Message to Update
   * @returns Promise<ChatConversation>
   */
  async postMessage(
    conversationId: string,
    value: ChatMessage
  ): Promise<ChatConversation> {
    try {
      const { data } = await this.chatService.post<ChatConversationResponse>(
        `/api/conversations/${conversationId}/messages`,
        value,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )

      return data.item
    } catch (err) {
      store.dispatch('snackbar/showSnackbar', {
        text: 'Request Error: ' + err,
        color: 'error'
      })
      console.error(err)
      throw new Error(err as string)
    }
  }

  /**
   * ASYNC, Post/save conversation
   * @param value , ChatConversation, Current Conversation Object
   * @returns Promise<ChatConversation>
   */
  async postConversation(value: ChatConversation): Promise<ChatConversation> {
    try {
      const { data } = await this.chatService.post<ChatConversationResponse>(
        '/api/conversations',
        value,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )

      return data.item
    } catch (err) {
      store.dispatch('snackbar/showSnackbar', {
        text: 'Request Error: ' + err,
        color: 'error'
      })
      console.error(err)
      throw new Error(err as string)
    }
  }

  /**
   * PROMISE, Patch/Update a Conversation
   * @param conversationId , string, Current Conversation ID
   * @param value , ChatConversation, Current Chat Conversation to Patch
   * @returns
   */
  patchConversation(conversationId: string, value: Object) {
    try {
      const response = this.chatService.patch<ChatConversationResponse>(
        `/api/conversations/${conversationId}`,
        value,
        {
          headers: {
            TellusDate: Date.now()
          }
        }
      )

      return response
    } catch (err) {
      store.dispatch('snackbar/showSnackbar', {
        text: 'Request Error: ' + err,
        color: 'error'
      })
      console.error(err)
      throw new Error(err as string)
    }
  }
}
