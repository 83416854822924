













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Chance from 'chance'
import {
  User,
  ChatConversation,
  ChatMessage,
  Environment,
  EnvironmentUser,
  FileManagerFile
} from '@/models'
import ChatService from '@/services/ChatService'
import UploadModal from '@/components/fields/file/UploadModal.vue'
import { ChatState } from '@/models/Chat'
import getEnv from '@/plugins/getEnv'
import moment from '@/plugins/moment'
import axios from 'axios'

const chance = new Chance()

@Component({
  components: {
    UploadModal
  }
})
export default class EnvironmentTellusWidget extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser

  initializing = true
  sessionId = ''
  initError = ''

  // Open navigation-drawer chat
  set openChat(v: boolean) {
    this.$emit('input', v)
  }

  get openChat() {
    return this.value
  }

  get drawerStyle() {
    return {
      'background-color': this.$vuetify.theme.dark
        ? '#1B1C18!important'
        : '#FDFCF5!important'
    }
  }

  mounted() {
    window.onmessage = this.handleMessage.bind(this)
    setTimeout(() => this.initialize(), 5000)
  }

  beforeDestroy() {
    window.onmessage = null
  }

  handleMessage(e: MessageEvent) {
    if(typeof e.data !== 'string') return
    const { type, payload } = JSON.parse(e.data)
    console.log('handleMessage', type, payload)
    switch (type) {
      case 'tellus:ready':
        this.initializing = false
        break
      case 'tellus:show_history':
        this.openChat = false
        this.$router.push('/manage/tickets')
        break
      case 'tellus:unreadCount':
        this.$emit('unreadMessageCountByClient', payload.count)
        break
    }
  }

  async initialize() {
    this.initializing = true
    this.initError = ''
    this.sessionId = ''
    try {
      const apiUrl = new URL(getEnv('VUE_APP_API_ENDPOINT'))
      const tellusUrl =
        getEnv('VUE_APP_CHAT_API_URL') ||
        'https://tellus-v2-beta.services.simplex.biz'
      const tempSessionId = btoa(
        JSON.stringify({
          d: apiUrl.host,
          s: this.$store.getters['auth/session'],
          t: Date.now()
        })
      )
      const { data } = await axios.get(`${tellusUrl}/api/auth/token`, {
        headers: {
          Authorization: `Codeless ${this.environment._id} ${tempSessionId}`,
          TellusDate: Date.now()
        }
      })
      this.sessionId = data.token
      // this.initializing = false
    } catch (e) {
      console.error(e)
      this.initError = e.message
    }
  }

  get channels() {
    if (
      this.environment.tellusChannels &&
      this.environment.tellusChannels.length > 0
    ) {
      return this.environment.tellusChannels.map((c) => ({
        label: c.category,
        value: c.channelName
      }))
    }
    return [{ label: 'Soporte', value: this.environment._id }]
  }

  get frameUrl() {
    if (!this.sessionId) return ''
    const url = new URL('https://tellus.simplex.biz/')
    url.searchParams.set('token', this.sessionId)
    url.searchParams.set('theme', this.$vuetify.theme.dark ? 'dark' : 'light')
    url.searchParams.set('channels', btoa(JSON.stringify(this.channels)))
    return url.toString()
  }
}
